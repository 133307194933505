var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ts-page-title',{attrs:{"title":_vm.$t('customer.pageTitle'),"breadcrumb":[
            { text: _vm.$t('home'), href: '/' },
            {
                text: _vm.$t('customer.pageTitle'),
                active: true
            }
        ]}}),_c('ts-panel',[_c('ts-panel-wrapper',{staticClass:"tw-flex tw-items-center tw-justify-between"},[_c('Button',{attrs:{"type":"info","ghost":""},on:{"click":function($event){return _vm.$router.push({ name: 'customer-create' })}}},[_vm._v(_vm._s(_vm.$t("addNew"))+" ")]),_c('div',{staticClass:"tw-justify-end tw-space-x-2"},[_c('Input',{style:(_vm.width),attrs:{"search":"","placeholder":_vm.$t('customer.search')},on:{"on-focus":function () { return (_vm.width = 'width: 500px'); },"on-blur":function () { return (_vm.width = 'width: 300px'); }},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('ts-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('filter')),expression:"$t('filter')"}],on:{"click":function($event){$event.preventDefault();_vm.visible = true}}},[_c('i',{staticClass:"fa fa-filter"})])],1)],1),_c('Table',{ref:"selection",attrs:{"columns":_vm.columns,"data":_vm.resources,"size":"small","loading":_vm.loading,"max-height":"700","stripe":""},on:{"on-select":_vm.onSelectCheck,"on-select-cancel":_vm.onSelectUncheck,"on-select-all":_vm.onSelectAllCheck,"on-select-all-cancel":_vm.onSelectAllUncheck},scopedSlots:_vm._u([{key:"photo",fn:function(ref){
        var row = ref.row;
return [_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:({
                        src: _vm.photo(row),
                        error:
                            row.gender == 'Male'
                                ? require('@/assets/staff-default-man.png')
                                : require('@/assets/staff-default-women.png'),
                        loading: require('@/assets/Rolling-1s-200px.svg'),
                        preLoad: 1.3,
                        attempt: 1
                    }),expression:"{\n                        src: photo(row),\n                        error:\n                            row.gender == 'Male'\n                                ? require('@/assets/staff-default-man.png')\n                                : require('@/assets/staff-default-women.png'),\n                        loading: require('@/assets/Rolling-1s-200px.svg'),\n                        preLoad: 1.3,\n                        attempt: 1\n                    }"}],staticClass:"tw-rounded tw-h-8"})]}},{key:"branch",fn:function(ref){
                    var row = ref.row;
return [_vm._v(" "+_vm._s(row.branch ? row.branch.branch_name_en : "")+" ")]}},{key:"number_of_active_loan_allow",fn:function(ref){
                    var row = ref.row;
return [_c('InputNumber',{attrs:{"max":2,"min":1,"controls-outside":"","size":"small"},on:{"on-change":function () { return _vm.onChangeNumberActiveLoanAllow(row); }},model:{value:(row.number_of_active_loan_allow),callback:function ($$v) {_vm.$set(row, "number_of_active_loan_allow", $$v)},expression:"row.number_of_active_loan_allow"}})]}},{key:"address",fn:function(ref){
                    var row = ref.row;
return [(_vm.$root.$i18n.locale === 'en')?_c('span',[_vm._v(_vm._s(row.village ? row.village.name_en ? row.village.name_en + ", " : "" : "")+" "+_vm._s(row.commune ? row.commune.name_en ? row.commune.name_en + ", " : "" : "")+" "+_vm._s(row.district ? row.district.name_en ? row.district.name_en + ", " : "" : "")+" "+_vm._s(row.province ? row.province.name_en ? row.province.name_en + "." : "-" : "-")+" ")]):_c('span',[_vm._v(_vm._s(row.village ? row.village.name_kh ? row.village.name_kh + ", " : "" : "")+" "+_vm._s(row.commune ? row.commune.name_kh ? row.commune.name_kh + ", " : "" : "")+" "+_vm._s(row.district ? row.district.name_kh ? row.district.name_kh + ", " : "" : "")+" "+_vm._s(row.province ? row.province.name_kh ? row.province.name_kh + "." : "-" : "-")+" ")])]}},{key:"document_type",fn:function(ref){
                    var row = ref.row;
return [_vm._v(" "+_vm._s(row.document_type ? row.document_type.doc_type_en : "")+" ")]}},{key:"action",fn:function(ref){
                    var row = ref.row;
return [_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('edit')),expression:"$t('edit')"}],staticClass:"text-primary ml-2",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.onEdit(row)}}},[_c('Icon',{attrs:{"type":"ios-create","size":"20"}})],1),_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('employee.move')),expression:"$t('employee.move')"}],staticClass:"text-orange mr-2",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.onMoveBrance(row)}}},[_c('Icon',{attrs:{"type":"ios-share-outline","size":"20"}})],1),_c('Poptip',{attrs:{"confirm":"","title":_vm.$t('areYouSure'),"transfer":true,"ok-text":_vm.$t('yes'),"cancel-text":_vm.$t('no')},on:{"on-ok":function($event){return _vm.onDelete(row)}}},[_c('Poptip',{attrs:{"confirm":"","title":_vm.$t('areYouSure'),"transfer":true,"ok-text":_vm.$t('yes'),"cancel-text":_vm.$t('no')},on:{"on-ok":function($event){return _vm.onDelete(row)}}},[_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('delete')),expression:"$t('delete')"}],staticClass:"ml-2 text-danger",attrs:{"disabled":row._deleting}},[(row._deleting)?_c('i',{staticClass:"fas fa-circle-notch fa-spin"}):_c('Icon',{attrs:{"type":"ios-trash","size":"20"}})],1)])],1)]}}])}),_c('div',{staticClass:"tw-flex tw-items-center tw-justify-between tw-p-4"},[_c('div',[_c('ts-button',{staticClass:"tw-mr-2 btn-sm",attrs:{"color":"success","outline":"","disabled":_vm.resources.length <= 0 || _vm.exporting},on:{"click":_vm.onExportExcelList}},[(_vm.exporting)?_c('i',{staticClass:"fas fa-circle-notch fa-spin"}):_c('i',{staticClass:"far fa-file-excel"}),_vm._v(" "+_vm._s(_vm.$t("exportExcel"))+" ("+_vm._s(_vm.customer_id_lists.length == 0 ? _vm.$t("all") : _vm.customer_id_lists.length)+") items")]),_c('ts-button',{staticClass:"tw-mr-2 btn-sm",attrs:{"color":"warning","outline":"","disabled":_vm.resources.length <= 0 ||
                            _vm.customer_id_lists.length == 0},on:{"click":_vm.moveBranch}},[_c('Icon',{attrs:{"type":"ios-swap","size":"15"}}),_vm._v(" "+_vm._s(_vm.$t("customer.moveBranch"))+" ("+_vm._s(_vm.customer_id_lists.length)+") "+_vm._s(_vm.$t("customer.profile")))],1)],1),_c('ts-i-pagination',{on:{"navigate":_vm.fetchData},model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}})],1)],1),_c('Modal',{attrs:{"draggable":"","sticky":"","scrollable":"","mask":true,"footer-hide":true,"z-index":1022,"title":_vm.$t('customer.moveBranch'),"width":"500"},model:{value:(_vm.showForm),callback:function ($$v) {_vm.showForm=$$v},expression:"showForm"}},[_c('move-branch',{ref:"move_branch",attrs:{"customers":_vm.customers},on:{"fetchData":_vm.fetchData,"cancel":_vm.cancel}})],1),_c('Drawer',{attrs:{"title":_vm.$t('filter'),"closable":false,"value":_vm.visible,"width":"300px"},on:{"on-close":function () { return (_vm.visible = false); }}},[_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-col-label control-label"},[_vm._v(_vm._s(_vm.$t("branchName")))]),_c('ts-branch-filter',{on:{"filter":function (value) { return (_vm.branch_id = value); }}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }